<template>
  <div class="course">
    <TabPageLayout activeCode="yizhongkecheng">
      <div class="container internationalCourse">
        <div class="div_mod">
          <div class="div_tit" v-for="(block, index) in blocks" :key="block.id">
            <h2>{{ block.title }}</h2>
            <div v-html="block.bz"></div>

            <div>
              <div class="div_mod1" v-if="block.code === 'BusinessCard'">
                <ul class="ul_nulist numCount">
                  <li v-for="subItem in blockChildren[index]" :key="subItem.id">
                    <div class="num" v-html="subItem.bz"></div>
                    <p>{{ subItem.title }}</p>
                  </li>
                </ul>
              </div>

              <div
                class="div_mod3"
                v-else-if="block.code === 'CurriculumSystem'"
              >
                <ul>
                  <li v-for="subItem in blockChildren[index]" :key="subItem.id">
                    <div class="div1">
                      <img :src="subItem.bgimg" alt="" />
                      <p>{{ subItem.title }}</p>
                    </div>
                    <div class="div2">
                      <h4>{{ subItem.title.split(" ")[0] }}</h4>
                      <span>{{ subItem.title }}</span>
                      <div v-html="subItem.bz"></div>
                      <a @click="toBlockDetailPage(subItem.id)"> 了解更多 </a>
                    </div>
                  </li>
                </ul>
              </div>
              <div
                class="div_mod4"
                v-else-if="block.code === 'DiversifiedExploration'"
              >
                <ul>
                  <li
                    style="padding-left: 0"
                    v-for="subItem in blockChildren[index]"
                    :key="subItem.id"
                  >
                    <img :src="subItem.bgimg" alt="" />
                    <div class="div_info">
                      <img :src="subItem.imgbz" alt="" />
                      <h3>{{ subItem.title }}</h3>
                      <div class="bz">
                        <div v-html="subItem.bz"></div>
                      </div>
                      <a @click="toBlockDetailPage(subItem.id)"> 了解更多 </a>
                    </div>
                  </li>
                </ul>
              </div>
              <div v-else>
                <div class="lunbo article-lunbo">
                  <div class="swiper-container">
                    <ul class="swiper-wrapper">
                      <li
                        class="swiper-slide"
                        v-for="article in blockArticles[index]"
                        :key="article.id"
                      >
                        <ArticleCard
                          :article="article"
                          activeCode="yizhongkecheng"
                        />
                      </li>
                    </ul>
                  </div>
                  <div class="direction">
                    <span class="s_prev"><i></i></span>
                    <span class="s_next"><i></i></span>
                  </div>
                </div>
                <div class="div_codeimg" :style="{
                  background: `url(${block.bgimg}) no-repeat center`
                }">
                  <div class="div_code">
                    <img :src="block.imgbz" alt="">
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </TabPageLayout>
  </div>
</template>

<script>
import TabPageLayout from './components/TabPageLayout.vue'
import { useGetCategories } from './utils/useGetCategories'
import portalApi from '../../../lvya-common/src/api/eos/portal'
import ArticleCard from './components/ArticleCard.vue'

export default {
  components: {
    TabPageLayout,
    ArticleCard,
  },
  computed: {
    ...mapState({
      nav: (state) => state.yzsyStore.nav,
    }),
    typeId() {
      return (
        this.nav?.find((item) => item.code === 'yizhongkecheng')?.stypes[1]
          ?.id || undefined
      )
    },
    schoolYear() {},
  },
  data() {
    return {
      blocks: [],
      blockArticles: [],
      blockChildren: [],
    }
  },
  methods: {
    toBlockDetailPage(typeId) {
      this.$router.push({
        path: '/portalYZSYCurriculumSystem',
        query: {
          typeId,
        },
      })
    },
    async getBlocks() {
      const categories = await useGetCategories(this.typeId)
      console.log({ categories })
      this.blocks = categories.list
    },
    async getBlockChildren() {
      for (const block of this.blocks) {
        const children = await useGetCategories(block.id)
        this.blockChildren.push(children.list)
      }
    },
    async getArticles() {
      // const portalSchoolInfo = JSON.parse(
      //   localStorage.getItem('portalSchoolInfo')
      // )
      const articles = []
      for (const block of this.blocks) {
        console.log(block, 'block')
        let reqData = {
          noSchoolId: true,
          schoolId: '7',
          pageNum: 1,
          pageSize: 999,
          typeId: block.id,
          isContent: 'Y',
          applyState: 'Y',
        }
        let resData = await portalApi.getArtsByPage(reqData)
        console.log(resData, 'articles')
        articles.push(resData?.list)
        console.log(articles, 'articles')
      }
      this.blockArticles = articles
    },
  },
  async mounted() {
    await this.getBlocks()
    await this.getBlockChildren()
    await this.getArticles()

    $('.internationalCourse .div_mod3 ul li').hover(
      function() {
        var obj = $(this)
        $(obj).find('.div2').stop().animate({ opacity: 1 }, 300)
      },
      function() {
        var obj = $(this)
        $(obj).find('.div2').stop().animate({ opacity: 0 }, 300)
      }
    )

    $('.internationalCourse .div_mod4 ul li').hover(
      function() {
        var obj = $(this)

        $(obj)
          .find('.div_info')
          .stop()
          .animate({ 'padding-top': '.6rem', opacity: 1 }, 300)

        $(obj).addClass('lihover')
      },
      function() {
        var obj = $(this)

        $(obj)
          .find('.div_info')
          .stop()
          .animate({ 'padding-top': '1.2rem', opacity: 1 }, 300)

        $(obj).removeClass('lihover')
      }
    )
    new Swiper('.lunbo .swiper-container', {
      loop: false,
      slidesPerView: 1, // 一行显示3个
      spaceBetween: 30,
      navigation: {
        nextEl: '.lunbo .s_next',
        prevEl: '.lunbo .s_prev',
      },

      breakpoints: {
        1024: {
          // 当屏幕宽度大于等于320
          slidesPerView: 3,
          spaceBetween: 30,
        },
      },
    })
  },
}
</script>

<style lang="less" scoped>
// @import "./style/common.css";
@import "./style/course.css";
@import "./style/global.css";
.container .div_tit .div2 span {
  color: #fff;
  text-align: center;
  display: block;
  width: 100%;
}
.div_info {
  .bz *{
    text-align: center !important;
    color: #fff !important;
    margin-bottom: 0.1rem;
  }
}

// .article-lunbo {
//   position: relative;
// }
.lunbo.article-lunbo {
  background: transparent;
}
.lunbo ul {
  /deep/ li {
    background: #fff;
    box-shadow: 0px 1px 9.8px 0.2px rgba(0, 0, 0, 0.1);
    &:hover {
      .imgbox img {
        transform: scale(1.1);
      }
    }
    .imgbox {
      overflow: hidden;
      img {
        transform: scale(1);
        transition: transform 0.3s linear;
      }
    }
    .txtbox {
      padding: 0.3rem;
      overflow: hidden;
      text-align: center;
      b {
        display: none;
      }
      h3 {
        text-align: left;
        font-size: 0.24rem;
        color: #333;
        line-height: 1.5;
        margin-bottom: 0.15rem;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        line-clamp: 2;
        -webkit-box-orient: vertical;
      }
      p {
        font-size: 0.16rem;
        color: #555;
        opacity: 0.8;
        line-height: 1.5;
        margin-bottom: 0.15rem;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        line-clamp: 2;
        -webkit-box-orient: vertical;
      }
      span {
        display: block;
        width: 1.3rem;
        height: 0.48rem;
        line-height: 0.46rem;
        text-align: center;
        border: 1px solid #f5a100;
        font-size: 0.16rem;
        color: #f5a100;
        border-radius: 10px;
        float: right;
        // position: absolute;
        right: 0.3rem;
        bottom: 0.3rem;
      }
    }
  }
}

@media screen and (max-width: 1023px) {
  .direction span {
    top: 50%;
  }
}
/deep/.num {
  p {
    font-size: 0.6rem !important;
    color: #f5a100 !important;
    margin-bottom: 0.1rem !important;
  }
}
</style>
